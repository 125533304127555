import React from 'react';
import CombinedNavbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './About.css'; // Assuming you have a CSS file for styling
import imgageside from '../images/colab1.png';
import Events from './Events/Events';
import Achivement from './Achivement/Achivement.js';

const About = () => {
  return (
    <>
      <CombinedNavbar />
      <section id="about-section">
        <div className="about-overlay">
          <div className="elementor-container elementor-column-gap-default">
            <div className='Abouttextimage'>
            <div className="elementor-column">
              <h1 className='org-overview-heading'>KHUB</h1>
              <p className='org-overview-matter'>K – Hub is a center established in 2022, at Kakinada Institute of Engineering and Technology (KIET) in affiliation with International Institute of Information Technology, Hyderabad (IIIT-H). We empower your future by offering cutting-edge internships, bridging academics with professional excellence. With us, you're not just gaining an internship; you're gaining a pathway to shaping the world around you. Welcome to Khub – where modern excellence meets your boundless potential.</p>
            </div>
            <div className="elementor-column">
              <img src={imgageside} alt="IIIT-Hyderabad" className='Imageinside' />
            </div>
            </div>
          </div>
          <div className="about-details">
            <h2 className='org-overview-heading elementor-container'>Our Mission</h2>
            <div className="elementor-column">
              <p className="elementor-container elementor-column-gap-default">
                At K-Hub, our mission is to empower students with the skills, knowledge, and mentorship needed to thrive in the ever-evolving tech landscape. Through immersive programs, personalized mentorship, and industry partnerships, we aim to cultivate a community of lifelong learners and leaders who are equipped to tackle the challenges of tomorrow.
              </p>
            </div>
            <h2 className='org-overview-heading elementor-container'>Our Approach</h2>
            <div className="elementor-column">
              <p className='elementor-container elementor-column-gap-default'>
                We believe in learning by doing. Our programs are designed to provide students with practical, hands-on experience working on real-world projects. From AI and machine learning to cybersecurity and blockchain, our projects cover a wide range of cutting-edge technologies, allowing students to explore their interests and develop valuable skills.
              </p>
            </div>
            <h2 className='org-overview-heading elementor-container'>Our Impact</h2>
            <div className="elementor-column">
              <p className="elementor-container elementor-column-gap-default">
                Since our inception, we have made a significant impact on the lives of students and the broader tech community. Our graduates have gone on to pursue successful careers in top tech companies, launch their own startups, and contribute to groundbreaking research and innovation. Through our projects, events, and initiatives, we continue to push the boundaries of what's possible and inspire the next generation of tech leaders.
              </p>
            </div>
            <h2 className='org-overview-heading elementor-container'>Events</h2>
            <div className="elementor-column">
             <Events/>
            </div>  
            <h2 className='org-overview-heading elementor-container'>Acheviments</h2>
            <div className="elementor-column">
             <Achivement/>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;



/* <div className="about-details">
            <h2>Our Mission</h2>
            <div className="mission-content">
              <p>
                At K-Hub, our mission is to empower students with the skills, knowledge, and mentorship needed to thrive in the ever-evolving tech landscape. Through immersive programs, personalized mentorship, and industry partnerships, we aim to cultivate a community of lifelong learners and leaders who are equipped to tackle the challenges of tomorrow.
              </p>
            </div>
            <h2>Our Approach</h2>
            <div className="approach-content">
              <p>
                We believe in learning by doing. Our programs are designed to provide students with practical, hands-on experience working on real-world projects. From AI and machine learning to cybersecurity and blockchain, our projects cover a wide range of cutting-edge technologies, allowing students to explore their interests and develop valuable skills.
              </p>
            </div>
            <h2>Our Impact</h2>
            <div className="impact-content">
              <p>
                Since our inception, we have made a significant impact on the lives of students and the broader tech community. Our graduates have gone on to pursue successful careers in top tech companies, launch their own startups, and contribute to groundbreaking research and innovation. Through our projects, events, and initiatives, we continue to push the boundaries of what's possible and inspire the next generation of tech leaders.
              </p>
            </div>
            <h2>Join Us</h2>
            <div className="join-us-content">
              <p>
                Whether you're a student looking to kickstart your tech career, an alum eager to give back to your alma mater, or a professional passionate about mentoring the next generation, we invite you to join us on this exciting journey. Together, we can unlock the full potential of technology and create a brighter future for all.
              </p>
            </div>
          </div>
        </div> */