import React, { useState, useEffect } from 'react';
import AchivementData from './Achivementdata.json'; // Assuming you have a JSON file for Achivement data
import './Achivements.css';
import AchivementPopup from "./Achivementpopup"

const AchivementCard = ({ Achivement, onClick }) => (
  <div className="Achivement-card" onClick={() => onClick(Achivement)}>
    <img src={Achivement.image} alt={Achivement.title} />
    <h3>{Achivement.title}</h3>
  </div>
);

const Achivements = () => {
  const [selectedAchivement, setSelectedAchivement] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [cardsPerRow, setCardsPerRow] = useState(4); // Default to 4 cards per row

  useEffect(() => {
    const handleResize = () => {
      // Adjust number of cards per row based on screen width
      if (window.innerWidth <= 480) {
        setCardsPerRow(1); // Mobile view: 1 card per row
      } else if (window.innerWidth <= 768) {
        setCardsPerRow(2); // Tablet view: 2 cards per row
      } else {
        setCardsPerRow(4); // Desktop view: 4 cards per row
      }
    };
  
    // Call handleResize initially and add event listener for window resize
    handleResize();
    window.addEventListener('resize', handleResize);
  
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const handleAchivementClick = (Achivement) => {
    setSelectedAchivement(Achivement);
  };

  const handleClosePopup = () => {
    setSelectedAchivement(null);
  };

  const handleNext = () => {
    if (startIndex + cardsPerRow < AchivementData.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <div className="Achivements-container">
      <div className="nav-icons">
        <button onClick={handlePrevious}>{'<'}</button>
      </div>
      <div className="cards" style={{display:"flex"}}>
        {AchivementData.slice(startIndex, startIndex + cardsPerRow).map((Achivement, index) => (
          <AchivementCard
            key={index}
            Achivement={Achivement}
            onClick={handleAchivementClick}
          />
        ))}
      </div>
      <div className="nav-icons">
        <button onClick={handleNext}>{'>'}</button>
      </div>
      {selectedAchivement && (
        <AchivementPopup
          Achivement={selectedAchivement}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Achivements;
