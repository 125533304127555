import React from "react";
import "./Card.css"; // Import the CSS file for styling
import rksir from "../images/rksir.jpg";
import revathimam from "../images/revathimam.jpeg";
import viswamsir from "../images/viswamsir1.jpg";
import RLsir from "../images/Ramesh-Loganathan.jpg";

function Card() {
  return (
    <section id="advisors-section">
      <div className="cards-head">
        <h2>Program Advisors</h2>
      </div>
      <div className="card-container">
        <div className="card">
          <img src={RLsir} alt="Card 1" />
          <div className="card-content">
            <h2>Ramesh Loganathan</h2>
            <p>
              Professor of Practice,<br/> Co-Innovation,
              <br />
              IIIT-Hyderabad.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={viswamsir} alt="Card 2" />
          <div className="card-content">
            <h2>P Viswam</h2>
            <p>
              Chairman,
              <br />
              KIET Group-korangi.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={revathimam} alt="Card 3" />
          <div className="card-content">
            <h2>D Revathi</h2>
            <p>
              Principal,
              <br />
              KIET-Korangi.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={rksir} alt="Card 4" />
          <div className="card-content">
            <h2>Y Rama Krishna</h2>
            <p>
              Principal,
              <br />
              KIET W-Korangi.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Card;
