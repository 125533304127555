import React, { useState } from "react";
import "./Mainpage.css";
import Card from "../Advisors/Card";
import collab1 from "../images/colab1.jpeg";
import Footer from "../Footer/Footer";
import Form from "../Connectus/Form";
import Navbar from "../Navbar/Navbar";
import Logo from "../images/favicon (1).png";
import batch23 from "../images/23batch.jpeg";
import batch24 from "../images/24batch.jpeg";
import batch25 from "../images/25 Batch.png";
function Mainpage() {
  return (
    <>
      <Navbar />
      <div>
        {/* section1 */}
        <section id="section1">
        <div class="space">
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
          </div>
          <div className="hero">
            <div className="hero1">
              <h1 className="title">
                Welcome To <br />
                K-HUB
              </h1>
            </div>
            <div className="hero2">
              <img src={Logo} alt="" />
            </div>
          </div>
          {/* <div className="floating">
            <img src={Logo} alt="" />
          </div> */}         
        </section>
        <div className="svgdiv">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
              fill="#022d77"
              fill-opacity="1"
            />
          </svg>
        </div>
        {/* section2 */}
        <section id="org-overview">
          <div class="org-overview">
            <div class="org-overview-heading">
              <h1>Organization Overview</h1>
            </div>
            <div class="org-overview-matter">
              <p>
                K – Hub is a center established in 2022, at Kakinada Institute
                of Engineering and Technology (KIET) in affiliation with
                International Institute of Information Technology, Hyderabad
                (IIIT-H). We empower your future by offering cutting-edge
                internships, bridging academics with professional excellence.
                With us, you're not just gaining an internship; you're gaining a
                pathway to shaping the world around you. Welcome to Khub – where
                modern excellence meets your boundless potential.
              </p>
            </div>
          </div>
        </section>

        {/* adding program advisors */}
        <Card />

        {/* section3 */}
        <section id="collaborations-section">
          <div class="collaborations-container">
            <h2>Collaborations</h2>
            <p>
              K_HUB is proud to be in collaboration with IIIT-H, providing
              dynamic internships and opportunities to students. Our current
              collaboration is just the beginning, and we look forward to
              expanding our network with future partnerships.
            </p>

            <h3>Current Collaboration</h3>
            <p>- IIIT Hyderabad (IIIT-H)</p>

            <h3>Future Collaborations</h3>
            <p>
              K_HUB is actively seeking collaborations with other companies and
              organizations. If you are interested in partnering with us, please
              reach out to explore exciting possibilities.
            </p>
          </div>
          <div className="collaborations-image">
            <img src={collab1} alt="IIITH" />
          </div>
        </section>

        {/* section4 */}
        {/* <section id="ourexperiences-section">
          <div className="ourexperiences-head">
            <h2>Our experiences</h2>
          </div>
          <div className="ourexperiences">
            <div className="experience batch-card"> {/* Added class batch-card 
              <img src={batch23} alt="img" />
              <div className="experience-content">
                <h2>23' Batch</h2>
                <p>
                  23 Batch Completion RCTS Screening Internship @IIIT HYDRABAD</p>
              </div>
            </div>

            <div className="experience batch-card"> 
              <img src={batch24} alt="img" />
              <div className="experience-content">
                <h2>24' Batch</h2>
                <p>24 Batch Completion RCTS Internship @IIIT HYDRABAD</p>
              </div>
            </div>

            <div className="experience batch-card"> 
              <img src={batch25} alt="img" />
              <div className="experience-content">
                <h2>25' Batch</h2>
                <p>
                  25 Batch Completion RCTS Internship @IIIT HYDRABAD</p>
              </div>
            </div>
          </div>
        </section>  */}
      </div>

      <Footer />
    </>
  );
}

export default Mainpage;
