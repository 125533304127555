import React from 'react';
import './Mentor.css'; // Import Mentor component CSS
import AluminiData from './alumniData.json'; // Import mentor data

const Alumini = () => {
  return (
    <div className="mentor-container">
      <h2 className="mentor-title">Alumni</h2>
      <div className='mentor-table-container'>
      <table className="mentor-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody> 
          {AluminiData.map((alumni, index) => (
            <tr key={index}>
              <td>{alumni.name}</td>
              <td>{alumni.designation}</td>
              <td>{alumni.email}</td>
              <td>{alumni.company}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default Alumini;
