// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Mainpage from './components/Mainpage/Mainpage';
import Gallery from './components/Gallery/Gallery';
import About from './components/About/About';
import Connect from './components/Connectus/Form';
import CombinedNavbar from './components/Navbar/Navbar';
import FolderPage from './components/FolderPage/FolderPage';
import MentorsComponent from'./components/Table/Mentors.jsx'
import Alumini from'./components/Table/Alumni';
import TeamTable from './components/Teams/teams.jsx';


const App = () => {
  return (
    <Router>
      <CombinedNavbar />
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about" element={<About />} />
        <Route path="/mentor" element={<MentorsComponent />} />
        <Route path="/alumini" element={<Alumini />} />
        <Route path="/teams" element={<TeamTable />} />

        <Route path="/connect" element={<Connect />} />
        <Route path="/gallery/:folderName" element={<FolderPage />} />
      </Routes>
    </Router>
  );
};

export default App;
