// Gallery.js
import React, { useState, useEffect } from "react";
import "./Gallery.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((key) => {
    const folderName = key.split("/")[1]; // Extract subfolder name
    const imageName = key.replace(/^.*[\\/]/, "").split(".")[0]; // Extract image name without file extension
    if (!images[folderName]) {
      images[folderName] = [];
    }
    images[folderName].push({
      src: r(key),
      name: imageName,
      folder: folderName, // Store folder name
    });
  });
  return images;
};

const imageContext = require.context(
  "../album",
  true,
  /\.(png|jpe?g|svg|jpg|jpeg|JPG)$/
);

const Gallery = () => {
  const [imageData, setImageData] = useState({});

  useEffect(() => {
    const loadedImages = importAll(imageContext);
    console.log(loadedImages); // Check if images with JPG extension are loaded
    setImageData(loadedImages);
  }, []);

  useEffect(() => {
    const loadedImages = importAll(imageContext);
    setImageData(loadedImages);
  }, []);

  return (
    <>
      <Navbar />
      <div className="gallery-container">
        <div class="gallery">
          <h1>Gallery</h1>
        </div>
        <div className="folder-container">
          {Object.keys(imageData).map((folderName) => (
            <Link
              key={folderName}
              to={`/gallery/${folderName}`}
              className="folder-card"
            >
              <h3 className="folderNamestyle">{folderName}</h3>
              <div className="image-preview">
                {/* Display a preview image from the folder, if available */}
                {imageData[folderName].length > 0 && (
                  <img
                    src={imageData[folderName][0].src}
                    alt={imageData[folderName][0].name}
                  />
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
