import React from "react";
import teamsData from "./teams.json";
import "./TeamTable.css";

const TeamTable = () => {
  const teamData = teamsData.teams;

  return (
    <div className="team-table">
      <h2>Team Information</h2>
      <div className="custom-table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th rowSpan="2">Team Number</th>
              <th rowSpan="2">Team Lead</th>
              <th rowSpan="2">Mentor</th>
              <th colSpan="2">Team Members</th>
            </tr>
            <tr>
              <th>Seniors</th>
              <th>Juniors</th>
            </tr>
          </thead>
          <tbody>
            {teamData.map((team, index) => (
              <React.Fragment key={index}>
                {team.subteams.map((subteam, subIndex) => (
                  <tr key={`subrow-${subIndex}`} className="team-row">
                    {subIndex === 0 && (
                      <>
                        <td rowSpan={team.subteams.length}>
                          {team.teamNumber}
                        </td>
                        <td rowSpan={team.subteams.length}>
                          <a href={team.teamLead.linkedIn}>
                            {team.teamLead.name}
                          </a>
                        </td>
                        <td rowSpan={team.subteams.length}>
                          <a href={team.mentor.linkedIn}>{team.mentor.name}</a>
                        </td>
                      </>
                    )}
                    <td>
                      <a href={subteam.senior.linkedIn} className="member-link">
                        {subteam.senior.name}
                      </a>
                    </td>
                    <td>
                      <a href={subteam.junior.linkedIn} className="member-link">
                        {subteam.junior.name}
                      </a>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamTable;
