import React from 'react';
import './Mentor.css'; // Import Mentor component CSS
import mentorData from './Mentor.json'; // Import mentor data

const MentorsComponent = () => {
  return (
    <div className="mentor-container">
      <h2 className="mentor-title">Mentors</h2>
      <div className='mentor-table-container'>
      <table className="mentor-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
            <th>Lab</th>
          </tr>
        </thead>
        <tbody>
          {mentorData.map((mentor, index) => (
            <tr key={index}>
              <td>{mentor.name}</td>
              <td>{mentor.designation}</td>
              <td>{mentor.email}</td>
              <td>{mentor.lab}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default MentorsComponent;
