import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import img from './footerimg.png';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css'; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="footer-content">
        <div className="left-half">
          <div className="logo">
            <img src={img} alt="KIET RCTS" />
          </div>
          <div className="social-icons">
            
            <div className="social-icon">
              <a
                href="https://www.instagram.com/khub_kiet_rcts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} style={{ color: 'white' }} />
              </a>
            </div>
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/k-hub-kiet/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} style={{ color: 'white' }} />
              </a>
            </div>
            <div className="social-icon">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} style={{ color: 'white' }} />
              </a>
            </div>
           
              
            <div className="social-icon">
              <a
                href="mailto:khub.helpdesk@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEnvelope} style={{ color: 'white' }} />
              </a>
            </div>
          </div>
        </div>
        <div className="middle">
          <div className="source-links">
            {/* <a href="https://rcts.iiit.ac.in/" target="_blank">RCTS</a><br /> */}
            <a href="http://www.kietgroup.com/" target="_blank"
              >KIET GROUP OF INSTITUTIONS</a
            >
          </div>
        </div>
        <div className="right-half">
        <p>USEFUL LINKS</p>
              <div className="useful-links">
              <ul style={{ listStyleType: 'none' }}>
              <li><a href="/">Home</a></li>
                <li><a href="/About">About</a></li>
                <li><a href="/gallery">Gallery</a></li>
                <li><a href="/contact">Contact</a></li>
                </ul>
              </div>
            
          
        </div>
      </div>
      <div className="copyrights">
        &copy; <span>2023 <strong>Kiet-hub(K-HUB).</strong> All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
