/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const CombinedNavbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <nav>
        <div className="navbar-content">
          <Link to="/">
            <h1>K-HUB</h1>
          </Link>
          <div className="menu-button" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
          </div>

          <ul className="nav-items">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>

            <li className="dropdown">
              <a href="" className="dropdown-link">
                Teams
              </a>
              <div className="dropdown-content">
                <a href="/alumini">Alumini</a>
                <a href="/mentor">Mentors</a>
                <a href="/teams">Teams</a>
              </div>
            </li>
            <li>
              <a href="/connect">Connect</a>
            </li>
          </ul>
        </div>
      </nav>

      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        {isSidebarOpen && (
          <div className="sidebar-elements">
            <Link to="/">Home</Link>
            <a href="/about">About</a>
            <a href="/gallery">Gallery</a>
            <a href="/alumini">Alumini</a>
            <a href="/mentor">Mentors</a>
            <a href="/teams">Teams</a>
            {/* Add more links for other teams as needed */}
            <a href="/connect">Connect</a>
          </div>
        )}
      </div>
    </>
  );
};

export default CombinedNavbar;
