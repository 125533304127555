import React from 'react';
import './Eventspopcss.css'

const EventPopup = ({ event, onClose }) => {
  return (
    <div className="event-popup">
              <span className="close-btn" onClick={onClose}>
          &times;
        </span>
      <div className="popupcontent">

        <h2>{event.title}</h2>
        <p>{event.description}</p>
        {event.gallery && (
          <a href={event.gallery} target="_blank" rel="noopener noreferrer">
            Gallery
          </a>
        )}
      </div>
    </div>
  );
};

export default EventPopup;
