import React from "react";

const MapContainer = () => {
  return (
    <div style={{ width: "100%", height: "400px", margin: "20px 0px" }}>
      <iframe
        title="Google Maps"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1431.527877660336!2d82.23912161786397!3d16.81359909426223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a38205e52dbce3d%3A0x794aa1538de4100f!2sKiet%20College!5e0!3m2!1sen!2sin!4v1714842017451!5m2!1sen!2sin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default MapContainer;
