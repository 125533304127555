import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './FolderPage.css';
import Footer from '../Footer/Footer';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import previous and next icons

const importAll = (r) => {
  let images = {};
  r.keys().forEach((key) => {
    const folderName = key.split('/')[1];
    const imageName = key.replace(/^.*[\\/]/, '').split('.')[0];
    if (!images[folderName]) {
      images[folderName] = [];
    }
    images[folderName].push({
      src: r(key),
      name: imageName,
      folder: folderName,
    });
  });
  return images;
};

const imageContext = require.context('../album', true, /\.(png|jpe?g|svg|jpg|jpeg|JPG)$/);

const FolderPage = () => {
  const { folderName } = useParams();
  const [folderImages, setFolderImages] = useState([]);
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0); // Track the index of the selected image
  const isMobile = window.innerWidth <= 768; // Check if the screen width is less than or equal to 768px (mobile)

  useEffect(() => {
    const loadedImages = importAll(imageContext);
    setFolderImages(loadedImages[folderName] || []);
  }, [folderName]);

  const openImage = (image, index) => {
    setIsImageClicked(true);
    setSelectedImage(image);
    setSelectedIndex(index);
  };

  const closePopup = () => {
    setIsImageClicked(false);
    setSelectedImage(null);
  };

  const navigate = (direction) => {
    const newIndex = direction === 'prev' ? selectedIndex - 1 : selectedIndex + 1;
    if (newIndex >= 0 && newIndex < folderImages.length) {
      setSelectedImage(folderImages[newIndex]);
      setSelectedIndex(newIndex);
    }
  };

  return (
    <div className={`folder-page-container ${isImageClicked ? 'container-clicked' : ''}`}>
      <div className='leftright'>
      <div className={`left-div ${isImageClicked ? 'left-div-clicked' : ''}`}>
        {folderImages.map((image, index) => (
          <img key={index} src={image.src} alt={image.name} onClick={() => openImage(image, index)} />
        ))}
      </div>7
      <div className={`right-div ${isImageClicked ? 'right-div-clicked' : ''}`}>
        {selectedImage && (
          <div className="popup" onClick={closePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <FaChevronLeft className="prev" onClick={() => navigate('prev')} />
              <img src={selectedImage.src} alt={selectedImage.name} />
              <FaChevronRight className="next" onClick={() => navigate('next')} />
            </div>
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default FolderPage;