// Achivementpopup.js
import React, { useState } from 'react';
import './Achivementspopcss.css';

const AchivementPopup = ({ Achivement, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === Achivement.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? Achivement.images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="Achivement-popup">
      <span className="close-btn" onClick={onClose}>
        &times;
      </span>
      <div className="popupcontent">
        <h2>{Achivement.title}</h2>
        <div className="image-carousel">
          <button onClick={handlePrevious}>{'<'}</button>
          <img
            src={Achivement.images[currentImageIndex]}
            alt={Achivement.title}
          />
          <button onClick={handleNext}>{'>'}</button>
        </div>
        <p>{Achivement.description}</p>
        {Achivement.gallery && (
          <a href={Achivement.gallery} target="_blank" rel="noopener noreferrer">
            Gallery
          </a>
        )}
      </div>
    </div>
  );
};

export default AchivementPopup;
