import React, { useState, useEffect } from 'react';
import eventData from './Eventsdata.json'; // Assuming you have a JSON file for event data
import './Events.css';
import EventPopup from "./Eventpopup"

const EventCard = ({ event, onClick }) => (
  <div className="event-card" onClick={() => onClick(event)}>
    <img src={event.image} alt={event.title} />
    <h3>{event.title}</h3>
  </div>
);

const Events = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [cardsPerRow, setCardsPerRow] = useState(4); // Default to 4 cards per row

  useEffect(() => {
    const handleResize = () => {
      // Adjust number of cards per row based on screen width
      if (window.innerWidth <= 480) {
        setCardsPerRow(1); // Mobile view: 1 card per row
      } else if (window.innerWidth <= 768) {
        setCardsPerRow(2); // Tablet view: 2 cards per row
      } else {
        setCardsPerRow(4); // Desktop view: 4 cards per row
      }
    };

    // Call handleResize initially and add event listener for resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  const handleNext = () => {
    if (startIndex + cardsPerRow < eventData.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <div className="events-container">
      <div className="nav-icons">
        <button onClick={handlePrevious}>{'<'}</button>
      </div>
      <div className="cards" style={{display:"flex"}}>
        {eventData.slice(startIndex, startIndex + cardsPerRow).map((event, index) => (
          <EventCard
            key={index}
            event={event}
            onClick={handleEventClick}
          />
        ))}
      </div>
      <div className="nav-icons">
        <button onClick={handleNext}>{'>'}</button>
      </div>
      {selectedEvent && (
        <EventPopup
          event={selectedEvent}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Events;
