import React, { useState } from "react";
import "./Form.css";
import CombinedNavbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import MapContainer from "./MapContainer";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    organisation: "",
    email: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, organisation, email, message } = formData;
    const subject = `Connect Form Submission from ${name}`;
    const body = `Name: ${name}\nOrganisation: ${organisation}\nEmail: ${email}\nMessage: ${message}`;
    const mailtoLink = `mailto:khub.helpdesk@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    setFormSubmitted(true);
  };

  return (
    <>
      <div>
        <CombinedNavbar />
      </div>

      <section id="connectpage-section">
        <div className="connectpage-overlay">
          {/* <h1>Welcome to K-HUB Team</h1> */}
        </div>
      </section>

      <div id="connectus">
        <div className="header-connect-page">
          <h2>Connect us</h2>
          <h3>Feel free to drop us a line to connect us</h3>
        </div>
        <div className="connect-page">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>NAME</label>
                <div className="text-box-container">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>ORGANISATION</label>
                <div className="text-box-container">
                  <input
                    type="text"
                    name="organisation"
                    value={formData.organisation}
                    onChange={handleChange}
                    placeholder="Organisation Name"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>EMAIL</label>
                <div className="text-box-container">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="example@gmail.com"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>MESSAGE</label>
                <div className="text-box-container">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
              </div>

              <button type="submit">Submit</button>
            </form>
            {formSubmitted && <p>Thank you for your submission!</p>}
          </div>

          <div className="info-container">
            <h2 style={{ fontSize: "xx-large" }}>Feel Free To Connect</h2>
            <p>
              <strong>Kiet-Hub(K-Hub)</strong>
              <br />
              Kakinada Institute of Engineering & Technology.
            </p>
            <div className="address">
              <p>
                B-block, K-HUB Workspace
                <br />
                Korangi,533461
                <br />
                Andhra pradesh,INDIA
              </p>
            </div>
            <div className="address">
              <strong>Phone : </strong>
              <a href="tel:+91 96189 13711">+91 96189 13711</a>
            </div>
            <div className="email">
              <strong>Email : </strong>
              <a href="mailto:khub.helpdesk@gmail.com">
                khub.helpdesk@gmail.com
              </a>
            </div>
            <div className="address">
              <strong>Website : </strong>
              <a href="http://www.kiethub.com">www.kiethub.com</a>
            </div>
          </div>
        </div>

        <div>
          <h2>Google Map</h2>
          <MapContainer />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Form;
